// --- Class Imports --- //
import Config from './RouteConfig';

// --- Layout Imports --- //
import { default as MainLayout } from '../layouts/Main';

// --- View Imports --- //
import { default as PaymentFormView } from 'views/PaymentFormView';
import { default as SuccessView } from 'views/SuccessView';

//Landing link and route config

const PaymentConfig = new Config({
  component: PaymentFormView,
  layout: MainLayout,
  paths: ['/payment'],
  title: 'Payment',
  href: '/payment',
  isProtected: false,
});

const SuccessConfig = new Config({
  component: SuccessView,
  layout: MainLayout,
  paths: ['/success'],
  title: 'Success',
  href: '/success',
  isProtected: false,
});

export const publicRoutes = [PaymentConfig, SuccessConfig];
export const protectedRoutes = [];

export default [...publicRoutes, ...protectedRoutes];
