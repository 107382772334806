import { BaseClass } from 'utilities';
import { DebitCardModel } from 'modules/DebitCard';
import { BankTransferModel } from 'modules/BankTransfer';
import { v4 as uuid } from 'uuid';
import ErrorModel from 'modules/Error/ErrorModel';

const PaymentFormModel = class extends BaseClass {
  constructor(props = {}) {
    super(props);
    this.sessionId = props.sessionId || uuid();
    // this.firstName = props.firstName || '';
    this.lastName = props.lastName || '';
    // this.lastFiveSSN = props.lastFiveSSN || '';
    this.email = props.email || '';
    this.loanNumber = props.loanNumber || '';
    this.paymentAmount = props.paymentAmount || '';
    this.feeAmount = props.feeAmount || 5;
    this.paymentType = props.paymentType || '';
    this.debitCard =
      new DebitCardModel(props.debitCard) || new DebitCardModel();
    this.bankTransfer =
      new BankTransferModel(props.bankTransfer) || new BankTransferModel();
    this.errors = new Array<new ErrorModel(props.ErrorModel)>[] || new Array(new ErrorModel())>[];
  }
};

export default PaymentFormModel;
