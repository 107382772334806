import React, { Fragment } from 'react';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  Box,
  Link,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
// import { UserMenu } from './components';
import palette from 'themes/palette';

//Icons
import AppsIcon from '@material-ui/icons/Apps';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MenuIcon from '@material-ui/icons/Menu';

//Services
// import { useAuth } from 'services/AuthService';

const useStyles = makeStyles((theme) => ({
  root: {
    // boxShadow: 'none',
    backgroundColor: '#f1f2ef',
    backgroundImage: 'url(/images/background_title.jpg)',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
    color: theme.palette.primary.main,
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      height: '35px',
    },
  },
  linksContainer: {
    display: 'flex',
    marginRight: '20px',
    alignItems: 'center',
    borderRight: '1px solid #bbbbbb',
    paddingRight: '28px',
  },
  //Navbar links
  linkBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '20px',
    color: palette.highway.main,
  },
  linkText: {
    color: palette.highway.dark,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '13px',
    textTransform: 'uppercase',
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px',
    borderBottom: '2px solid transparent',
    '&:hover': {
      color: palette.river.light,
      borderBottom: '2px solid ' + palette.river.light,
    },
  },
  selected: {
    fontWeight: 600,
    color: palette.river.mediumlight,
    borderBottom: '2px solid ' + palette.river.mediumlight,
  },
  // Dropdown menu links
  menuLink: {
    display: 'flex',
    alignItems: 'center',
    color: palette.highway.main,
    textDecoration: 'none',
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  // const { isAuthenticated } = useAuth();
  const classes = useStyles();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Box className="dont-print">
      <AppBar {...rest} className={clsx(classes.root, className)}>
        <Toolbar>
          {/* <RouterLink to="/payment"> */}
          <img
            alt="Logo"
            src="/images/logos/nwcu-logo.png"
            className={classes.logo}
          />
          {/* </RouterLink> */}
          <div className={classes.flexGrow} />

          {/* We only want to show links if user is logged in */}
          {/* {isAuthenticated ? ( */}
          <Fragment>
            {/* <Hidden xsDown>
              <Box className={classes.linksContainer}>
                <NavLink
                  to="/calendar"
                  className={clsx(classes.linkText, classes.linkBox)}
                  activeClassName={classes.selected}
                >
                  <CalendarTodayIcon style={{ marginBottom: '4px' }} />
                  <Box>Main Calendar</Box>
                </NavLink>
                <NavLink
                  to="/template"
                  className={clsx(classes.linkText, classes.linkBox)}
                  activeClassName={classes.selected}
                >
                  <AppsIcon style={{ marginBottom: '4px' }} />
                  <Box>Template</Box>
                </NavLink>
              </Box>
            </Hidden> */}

            {/* <Hidden smUp>
              <IconButton color="inherit" onClick={handleMenuClick}>
                <MenuIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <MenuItem onClick={handleMenuClose}>
                  <RouterLink to="/calendar" className={classes.menuLink}>
                    <CalendarTodayIcon style={{ marginRight: '6px' }} />
                    Main Calendar
                  </RouterLink>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <RouterLink to="/template" className={classes.menuLink}>
                    <AppsIcon style={{ marginRight: '6px' }} />
                    Template
                  </RouterLink>
                </MenuItem>
              </Menu>
            </Hidden> */}
          </Fragment>
          {/* ) : (
          ''
        )} */}

          {/* <UserMenu /> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
