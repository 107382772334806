import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

//Material UI
import {
  Box,
  Grid,
  CircularProgress,
  Button,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Components
import { CustomPopUpAlert } from 'components';

//Icons
import PersonIcon from '@material-ui/icons/Person';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

//Themes
import palette from 'themes/palette';

//Formik
import { TextField } from 'formik-material-ui';
import { Formik, Form, useFormikContext } from 'formik';

//Modules
import PaymentFormSchema from './PaymentFormSchema';
import PaymentFormModel from './PaymentFormModel';
import { BankTransfer } from 'modules/BankTransfer';
import { DebitCard } from 'modules/DebitCard';

//Serivces
import { usePaymentService } from 'services/PaymentService';
import { useCatalogService } from 'services/CatalogService';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '40px',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 20px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 10px',
    },
  },
  title: {
    fontSize: '35px',
    color: 'white',
    fontWeight: 600,
    letterSpacing: '1px',
  },
  section: {
    boxSizing: 'border-box',
    backgroundColor: 'white',
    borderLeft: '5px solid ' + palette.forest.light,
    padding: '25px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',

    color: palette.highway.main,
    marginBottom: '25px',
  },

  sectionTitle: {
    color: palette.highway.main,
    fontWeight: 700,
    fontSize: '25px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  radioContainer: {
    display: 'flex',
    marginBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  radioBox: {
    boxSizing: 'content-box',
    border: '1px solid ' + palette.river.verylight,
    margin: '5px',
    padding: '15px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 10px',
      marginBottom: '8px',
    },
    borderRadius: '5px',
    cursor: 'pointer',
    opacity: 0.85,
    '&:focus': {
      border: '3px solid ' + palette.river.dark,
      outline: 'none',
    },
  },

  radioBoxSelected: {
    boxSizing: 'content-box',
    border: '3px solid ' + palette.river.light,
    backgroundColor: palette.river.veryverylight,
    opacity: 1,
    // margin: 0,
    [theme.breakpoints.down('xs')]: {
      marginBottom: '8px',
    },
  },
  radioTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: 500,
  },
  radioDescription: { paddingTop: '8px', fontSize: '85%', fontWeight: 300 },
  subtitle: {
    marginBottom: '15px',
    fontWeight: '500',
    fontSize: '120%',
  },
  error: {
    color: '#e53935',
    marginLeft: '14px',
    marginRight: '14px',

    fontSize: '13px',
    letterSpacing: '0.33px',
    fontWeight: 400,
  },
  link: {
    textDecoration: 'none',
    color: '#ACBFAA',
    fontWeight: 400,
  },
  label: {
    fontSize: '0.75rem',
    marginBottom: '4px',
    transform: 'translate(0px, -6px)',
    color: '#263238',
  },
  value: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },
}));

const RadioBox = (props) => {
  const classes = useStyles();
  const { name, value, icon, title, description, ...rest } = props;
  const { values, setValues } = useFormikContext();

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (values[name] === value) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [values]);

  const radioBoxClick = () => {
    setValues({
      ...values,
      paymentType: value,
    });
  };

  return (
    <Box
      className={clsx(
        classes.radioBox,
        selected ? classes.radioBoxSelected : ''
      )}
      onClick={radioBoxClick}
      {...rest}
      tabIndex={0}
      role="button"
      onKeyDown={(e) => {
        if (e.which === 13) {
          radioBoxClick();
        }
      }}
    >
      <Box className={classes.radioTitle}>
        {icon} {title}
      </Box>
      <Box className={classes.radioDescription}>{description}</Box>
    </Box>
  );
};

const loading = (loadingMessage = 'Loading...') => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 300,
      }}
    >
      <CircularProgress style={{ marginBottom: '20px' }} color="secondary" />
      {loadingMessage}
    </Box>
  );
};

const PaymentForm = (props) => {
  const classes = useStyles();

  //Services
  const paymentService = usePaymentService();
  const catalogService = useCatalogService();

  const {
    openCustomPopUpAlert,
    closeCustomPopUpAlert,
    setPopUpAlertContent,
    setPopUpAlertTitle,
    setPopUpAlertType,
  } = props.popupActions;

  const {
    values,
    setValues,
    errors,
    touched,
    validateField,
    validateForm,
    setErrors,
    setTouched,
    ...rest
  } = useFormikContext();

  const [validated, setValidated] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState([]);

  useEffect(() => {
    catalogService.getPaymentTypes().then((response) => {
      setPaymentTypes(response.data);
    });
  }, []);

  useEffect(() => {
    if (paymentTypes.length === 1) {
      setValues({ ...values, paymentType: paymentTypes[0] });
    }
  }, [paymentTypes]);

  const validateInfo = () => {
    validateForm().then((errors) => {
      if (errors.lastName || errors.loanNumber) {
        const errorObject = {
          lastName: errors.lastName,
          loanNumber: errors.loanNumber,
        };
        setErrors(errorObject);
        setTouched(errorObject);
      } else {
        openCustomPopUpAlert();
        setPopUpAlertTitle('Loading');
        setPopUpAlertContent(loading('Validating Loan # and Last Name...'));
        setPopUpAlertType('loading');

        const info = {
          accountNumber: parseInt(values.loanNumber),
          lastName: values.lastName.trim(),
        };

        paymentService.validateLoanNumber(info).then((response) => {
          // console.log('response: ', response);
          if (response.data.status === 200) {
            setValidated(true);
            closeCustomPopUpAlert();
          } else {
            setPopUpAlertTitle('Error');
            setPopUpAlertContent(
              <Box>
              {response.data.errors.map(d => (<li >{d.display}</li>))}
              <br /><br />
              {response.data.sessionId}
            </Box>
            );
            setPopUpAlertType('error');
          }
        });
      }
    });
  };

  return (
    <Box>
      <Box className={classes.section}>
        <Box className={clsx('sentinel', classes.sectionTitle)}>
          <PersonIcon style={{ marginRight: '7px' }} />
          Personal Info
        </Box>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={6}>
            <TextField
              name="firstName"
              label="First Name"
              variant="outlined"
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              name="lastName"
              label="Last Name"
              variant="outlined"
              InputProps={{
                readOnly: validated ? true : false,
              }}
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <TextField
              name="lastFiveSSN"
              label="Last 5 of SSN"
              variant="outlined"
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              name="loanNumber"
              label="Loan Number"
              variant="outlined"
              InputProps={{
                readOnly: validated ? true : false,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="email"
              label="Email (optional)"
              variant="outlined"
              fullWidth
              helperText="Enter an email if you would like to receive a confirmation email of your payment."
            />
          </Grid>
        </Grid>
      </Box>
      <Button
        variant="contained"
        color="primary"
        style={{ display: validated ? 'none' : 'flex' }}
        onClick={validateInfo}
      >
        Next
      </Button>

      {validated ? (
        <Box>
          <Box className={classes.section}>
            <Box className={clsx('sentinel', classes.sectionTitle)}>
              <AttachMoneyIcon style={{ marginRight: '10px' }} />
              Payment Info
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="paymentAmount"
                  label="Payment Amount"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Box className={classes.label}>Fee Amount</Box>
                  <Box className={classes.value}> $ 5.00</Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.label}>Total Amount to Charge </Box>
                <Box className={classes.value}>
                  {' '}
                  ${' '}
                  {parseFloat(
                    parseFloat(values.feeAmount) +
                      (parseFloat(values.paymentAmount)
                        ? parseFloat(values.paymentAmount)
                        : 0)
                  ).toFixed(2)}
                </Box>
              </Grid>
              <Grid item xs={12}>
                {paymentTypes.length > 1 ? (
                  <Box>
                    <Box className={classes.subtitle}>
                      Select a Payment Method:
                    </Box>
                    <Box
                      className={classes.radioContainer}
                      style={{ display: 'flex', marginBottom: '20px' }}
                    >
                      <RadioBox
                        name="paymentType"
                        value="card"
                        title="Debit Card"
                        description="Click this box if your payment method is a Debit Card."
                        icon={
                          <CreditCardIcon style={{ marginRight: '10px' }} />
                        }
                        // style={{ marginRight: '15px' }}
                      />
                      <RadioBox
                        name="paymentType"
                        value="ach"
                        title="Bank Transfer"
                        description="Click this box if your payment method is a Bank Transfer."
                        icon={
                          <AccountBalanceIcon style={{ marginRight: '10px' }} />
                        }
                      />
                    </Box>
                    {errors.paymentType && touched.paymentType ? (
                      <Box className={classes.error}>{errors.paymentType}</Box>
                    ) : (
                      ''
                    )}
                  </Box>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
            {values.paymentType === 'card' ? (
              <Box>
                <DebitCard prefix="debitCard" />
              </Box>
            ) : values.paymentType === 'ach' ? (
              <Box>
                <BankTransfer prefix="bankTransfer" />
              </Box>
            ) : (
              ''
            )}
          </Box>
          <Button variant="contained" type="submit" color="primary">
            Make Payment
          </Button>
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

const PaymentFormWrapper = (props) => {
  const classes = useStyles();
  const history = useHistory();

  //Alert Popup
  const [customPopUpAlert, setCustomPopUpAlert] = useState(false);
  const [popUpAlertTitle, setPopUpAlertTitle] = useState('');
  const [popUpAlertContent, setPopUpAlertContent] = useState('');
  const [popUpAlertType, setPopUpAlertType] = useState('neutral');

  //Custom Alert PopUp Helpers
  const openCustomPopUpAlert = () => setCustomPopUpAlert(true);
  const closeCustomPopUpAlert = () => setCustomPopUpAlert(false);

  const popupActions = {
    setPopUpAlertContent: setPopUpAlertContent,
    setPopUpAlertTitle: setPopUpAlertTitle,
    setPopUpAlertType: setPopUpAlertType,
    openCustomPopUpAlert: openCustomPopUpAlert,
    closeCustomPopUpAlert: closeCustomPopUpAlert,
  };

  //Services
  const paymentService = usePaymentService();

  const makePayment = (values, resetForm) => {
    console.log('values', values);
    openCustomPopUpAlert();
    setPopUpAlertTitle('Loading');
    setPopUpAlertContent(loading());
    setPopUpAlertType('loading');

    try {
      const paymentInfo = {
        sessionId: values.sessionId,
        accountNumber: parseInt(values.loanNumber),
        lastName: values.lastName.trim(),
        fundType: values.paymentType,
        amount: parseFloat(values.paymentAmount) + parseFloat(values.feeAmount),
        feeAmount: parseFloat(values.feeAmount),
        branchId: '20',
        creditCard: {
          number: values.debitCard.cardNumber.trim(),
          nameOnCard: values.debitCard.nameOnCard.trim(),
          securityCode: values.debitCard.securityCode.trim(),
          expirationDate: values.debitCard.expirationDate,
          expirationMonth: values.debitCard.expirationDate.substring(0, 2),
          expirationYear: values.debitCard.expirationDate.substring(2, 6),
          billingAddress: values.debitCard.billingAddress,
        },
        ach: {
          accountNumber: values.bankTransfer.accountNumber.trim(),
          accountType: values.bankTransfer.accountType,
          effectiveDate: moment(values.bankTransfer.effectiveDate).toDate(),
          nameOnAccount: values.bankTransfer.nameOnAccount.trim(),
          routingNumber: values.bankTransfer.routingNumber.trim(),
        },
      };

      // console.log('payment info', paymentInfo);

      paymentService.makePayment(paymentInfo).then((response) => {
        console.log('response', response);
        if (response.data.status === 200) {
          closeCustomPopUpAlert();
          history.push({
            pathname: '/success',
            state: { ...response.data, email: values.email },
          });
        } else {
          setPopUpAlertTitle('Error');
          setPopUpAlertContent(
            <Box>
              {response.data.errors.map(d => (<li >{d.display}</li>))}
              <br /><br />
              {response.data.sessionId}
            </Box>
          );
          setPopUpAlertType('error');
          resetForm({ values: values });
        }
      });
    } catch {
      console.log('Something went wrong');
    }
  };
  return (
    <Box>
      <CustomPopUpAlert
        open={customPopUpAlert}
        title={popUpAlertTitle}
        type={popUpAlertType}
        onClose={closeCustomPopUpAlert}
        disableClose
        fullWidth
      >
        {popUpAlertContent}
      </CustomPopUpAlert>
      <Formik
        initialValues={new PaymentFormModel().toObject()}
        validationSchema={PaymentFormSchema}
        onSubmit={(values, { resetForm }) => {
          console.log('submitted values', values);
          makePayment(values, resetForm);
        }}
      >
        <Form>
          <PaymentForm popupActions={popupActions} />
        </Form>
      </Formik>
    </Box>
  );
};

export default PaymentFormWrapper;
