import React from 'react';
import './App.css';

//Recaptcha
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

//Theme
import { ThemeProvider } from '@material-ui/styles';
import theme from './themes';

import { ScrollToTop } from 'routes/components';

//Routes
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import Routes from './routes';

//Services
// import { AuthProvider } from 'services/AuthService';

const browserHistory = createBrowserHistory();

function App() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
    >
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          {/* <AuthProvider> */}
          <ScrollToTop />
          <Routes />
          {/* </AuthProvider> */}
        </Router>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
