// --- Imports --- //
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

// --- Custom Component Imports --- //
import { PublicRoute, ProtectedRoute } from './components';

// -- Config Imports -- //
import configs from './configs';

// --- Component --- //
const Routes = (props) => {
  return (
    <Switch>
      <Redirect exact from="/" to="/payment" />
      {configs.map((config) => {
        if (config.isProtected) {
          return (
            <ProtectedRoute exact key={config.key} {...config.getRoute()} />
          );
        }
        return <PublicRoute exact key={config.key} {...config.getRoute()} />;
      })}
    </Switch>
  );
};

// --- Exports --- //
export default Routes;
