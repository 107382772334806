import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';

//Material UI
import { Box, Grid, CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Icons
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import PrintIcon from '@material-ui/icons/Print';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

//Themes
import palette from 'themes/palette';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '40px',
    maxWidth: '800px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 10px',
    },
  },
  title: {
    fontSize: '35px',
    color: 'white',
    fontWeight: 600,
    letterSpacing: '1px',
  },
  hero: {
    height: '20vh',
    width: '100%',
    backgroundImage: 'url(/images/forest.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  section: {
    boxSizing: 'border-box',
    backgroundColor: 'white',
    borderLeft: '5px solid ' + palette.river.light,
    padding: '25px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',

    color: palette.highway.main,
    marginBottom: '25px',
  },
  sectionTitle: {
    color: palette.highway.main,
    fontWeight: 700,
    fontSize: '25px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  box: {
    border: '1px solid #dddddd',
    borderRadius: '15px',
    padding: '20px 20px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    marginBottom: '15px',
    backgroundColor: 'white',
  },
  detailLabel: {
    marginBottom: '5px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    fontSize: '90%',
    color: palette.mountain.light,
  },
  detailResult: {
    fontWeight: 300,
    fontSize: '120%',
  },

  detailGroup: {
    marginRight: '20px',
  },
}));

const SuccessView = (props) => {
  const classes = useStyles();

  const data = props.location.state;
  // console.log('data', data);

  return (
    <Box>
      <Box className="dont-print">
        <Box className={clsx(classes.hero)}>
          <Box className={clsx('sentinel', classes.title)}>Payment Success</Box>
          <Box
            style={{
              fontSize: '15px',
              color: 'white',
              fontWeight: 300,
              textTransform: 'uppercase',
              letterSpacing: '2px',
            }}
          >
            Thank you for your payment!
          </Box>
        </Box>
      </Box>
      <Box className={classes.container}>
        <Box className={clsx('print', classes.section)}>
          <Box className={clsx('sentinel', classes.sectionTitle)}>
            <AttachMoneyIcon style={{ marginRight: '10px' }} />
            Payment Details
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box className={classes.detailGroup}>
                <Box className={classes.detailLabel}> Loan Account #: </Box>
                <Box className={classes.detailResult}>{data.accountNumber}</Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.detailGroup}>
                <Box className={classes.detailLabel}>Name:</Box>
                <Box className={classes.detailResult}>{data.lastName}</Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.detailGroup}>
                <Box className={classes.detailLabel}>Confirmation #: </Box>
                <Box className={classes.detailResult}>
                  {data.confirmationNumber}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.detailGroup}>
                <Box className={classes.detailLabel}>Charged Amount: </Box>
                <Box className={classes.detailResult}>
                  ${parseFloat(data.amount).toFixed(2)}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.detailGroup}>
                <Box className={classes.detailLabel}>
                  Payment Amount to Loan:{' '}
                </Box>
                <Box className={classes.detailResult}>
                  $
                  {(
                    parseFloat(data.amount) - parseFloat(data.feeAmount)
                  ).toFixed(2)}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.detailGroup}>
                <Box className={classes.detailLabel}>Fee Amount: </Box>
                <Box className={classes.detailResult}>
                  ${parseFloat(data.feeAmount).toFixed(2)}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.detailGroup}>
                <Box className={classes.detailLabel}>Payment Method: </Box>
                <Box className={classes.detailResult}>
                  {data.fundType === 'ach'
                    ? 'Bank Transfer'
                    : 'card'
                    ? 'Debit Card'
                    : ''}
                  {/* <br />
                  {data.fundType === 'ach'
                    ? data.ach.accountNumber.replace(/\d(?=\d{4})/g, '*')
                    : data.fundType === 'card'
                    ? data.creditCard.number.replace(/d(?=\d{4})/g, '*')
                    : ''} */}
                  {/* ****-****-****-3890 */}
                </Box>
              </Box>
            </Grid>
            {data.email ? (
              <Grid item xs={12} sm={6}>
                <Box className={classes.detailGroup}>
                  <Box className={classes.detailLabel}>Confirm Sent To: </Box>
                  <Box className={classes.detailResult}>{data.email}</Box>
                </Box>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Box>
        <Box className={'dont-print'}>
          <Button
            variant="contained"
            color="primary"
            href="./payment"
            style={{ marginRight: '10px', marginBottom: '10px' }}
          >
            <KeyboardBackspaceIcon style={{ marginRight: '10px' }} />
            Make Another Payment
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.print();
            }}
            style={{ marginBottom: '10px' }}
          >
            <PrintIcon style={{ marginRight: '10px' }} />
            Print
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SuccessView;
