import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';

//Material UI
import { Box, Grid, CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Icons
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

//Themes
import palette from 'themes/palette';

//Modules
import { PaymentForm } from 'modules/PaymentForm';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '40px',
    maxWidth: '800px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 10px',
    },
  },
  title: {
    fontSize: '35px',
    color: 'white',
    fontWeight: 600,
    letterSpacing: '1px',
  },
  hero: {
    height: '20vh',
    width: '100%',
    backgroundImage: 'url(/images/forest.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subText: {
    fontWeight: 300,
    fontSize: '120%',
  },
  section: {
    boxSizing: 'border-box',
    backgroundColor: 'white',
    borderLeft: '5px solid ' + palette.brick.light,
    padding: '25px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',

    color: palette.highway.main,
    marginBottom: '25px',
  },

  sectionTitle: {
    color: palette.highway.main,
    fontWeight: 700,
    fontSize: '25px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const PaymentFormView = (props) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.hero}>
        <Box className={clsx('sentinel', classes.title)}>Make a Payment</Box>
      </Box>
      <Box className={classes.container}>
        <Box className={classes.section}>
          <Box className={clsx('sentinel', classes.sectionTitle)}>
            <ChatBubbleIcon style={{ marginRight: '7px' }} />
            Please Note
          </Box>
          <Box className={classes.subText}>
            <Box style={{ marginBottom: '15px' }}>
              You cannot make a payment to your Visa account on this website.
            </Box>
            <Box style={{ marginBottom: '15px' }}>
              Addtionally, this platform cannot be used to make a final payoff
              payment. Please contact NWCU for help making a final payoff
              payment.
            </Box>
            <Box>Online payments can only be made with a debit card.</Box>
          </Box>
        </Box>
        <PaymentForm />
      </Box>
    </Box>
  );
};

export default PaymentFormView;
