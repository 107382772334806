// --- Yup/Validation Imports --- //
import * as yup from 'yup';
import moment from 'moment';

import { DebitCardSchema } from 'modules/DebitCard';
import { BankTransferSchema } from 'modules/BankTransfer';

const PaymentFormSchema = yup.object().shape({
  // firstName: yup.string().label('First Name').required(),
  lastName: yup.string().label('Last Name').required(),
  // lastFiveSSN: yup.number().label('Last 5 of SSN').required(),
  email: yup.string().email().label('Email'),
  loanNumber: yup
    .number()
    .typeError('Loan Number must be a number')
    .label('Loan Number')
    .required(),
  paymentAmount: yup
    .number()
    .typeError('Payment Amount must be a number')
    .min(0)
    .max(2000)
    .label('Payment Amount')
    .required(),
  paymentType: yup.string().label('Payment Type').required(),
  debitCard: yup.object().when('paymentType', {
    is: 'card',
    then: DebitCardSchema,
    otherwise: yup.object().nullable(),
  }),
  bankTransfer: yup.object().when('paymentType', {
    is: 'ach',
    then: BankTransferSchema,
    otherwise: yup.object().nullable(),
  }),
});

export default PaymentFormSchema;

// .test('test-out', 'Out must be less than Headcount', function checkOut(
//   out
// ) {
//   const { headCount } = this.parent;
//   if (out <= headCount) {
//     return true;
//   }
//   return false;
// })
