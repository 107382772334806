// --- Imports --- //
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const PaymentService = class {
  constructor(recaptchaService) {
    this.client = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_PAYMENT_API_VERSION}/payment`,
      validateStatus: function () {
        return true;
      },
      headers: { 'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_API_SUB_KEY}` }
    });
    this.recaptchaService = recaptchaService || undefined;
  }
  ///////////////////////////

  async makePayment(paymentInfo) {
    const token = await this.recaptchaService();
    try {
      const { data: response, status } = await this.client.post(
        '/',
        paymentInfo,
        { headers: { token: token } }
      );
      return response;
    } catch (error) {
      return {};
    }
  }

  async validateLoanNumber(info) {
    const token = await this.recaptchaService();
    try {
      const { data: response, status } = await this.client.post(
        '/validate',
        info,
        { headers: { token: token } }
      );
      return response;
    } catch (error) {
      return {};
    }
  }

  // PUT EXAMPLE //
  async put(branchData) {
    const token = await this.recaptchaService();

    try {
      const { data: response, status } = await this.client.put(
        '/branch',
        branchData,
        {
          headers: { token: token },
        }
      );
      return response;
    } catch (error) {
      return {};
    }
  }

  // GET EXAMPLE //
  async get() {
    const token = await this.recaptchaService();
    try {
      const { data: response, status } = await this.client.get('/dailybranch', {
        params: {},
        headers: { token: token },
      });
      return response;
    } catch (error) {
      return {};
    }
  }

  // POST EXAMPLE //
  async post(object) {
    const token = await this.recaptchaService();
    try {
      const { data: response, status } = await this.client.post(
        '/dailybranch',
        object,
        { headers: { token: token } }
      );
      return response;
    } catch (error) {
      return {};
    }
  }

  /////////////////////
};

export const usePaymentService = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  return new PaymentService(executeRecaptcha);
};

export default new PaymentService();
